import qs from "qs"
import { TOKEN_NAME } from "./config"
import { storageGet, storageRemove, storageSet, CookieGet } from "./index"
import store from "@/store/index.js"
import router from "@/router/index.js"

import { fetchDelToken } from "@/api/login"
import { showMsgError, LOGIN_HOST } from "./index"

export function setToken(val = "") {
  storageSet(TOKEN_NAME, val)
}
export function getToken() {
  return storageGet(TOKEN_NAME) || ""
}
export function removeToken() {
  storageRemove(TOKEN_NAME)
}
//是否登录
export function checkIsLogin() {
  return store.state.userData.isLogin
}

//解析url上的ntoken并进行重新跳转
export function parseUrlNtoken() {
  console.log(window.location.href, qs)
  const { href, search, origin, pathname } = window.location
  if (href.indexOf("ntoken=") !== -1) {
    //从url解析出search
    const searchParse = qs.parse(search, {
      ignoreQueryPrefix: true,
    })
    const { ntoken = "" } = searchParse

    setToken(ntoken)

    window.location.href = `${origin}${pathname}`
  }
}

// 自动退出登录
// 登录后在cookie和localStorage都存了token
// 关闭整个浏览器后，cookie中的token会失效
// 再次打开浏览器的时候就给他自动退出登录
export function autoExit() {
  const storageToken = getToken()
  const cookieToken = CookieGet("token")
  if (!cookieToken && !!storageToken) {
    fetchDelToken({
      token: storageToken,
    })
    // bugfix：下面的代码应该放在fetchDelToken finally运行，Edge17不支持finally，因此单独拿出来
    store.commit("userData/USER_LOGOUT")
    removeToken()
    storageRemove("user")
    showMsgError("登录信息过期，请登录")
  }
}

export function toLoginWithUrl(path = "/main/home", query = null, pushOrReplace = true) {
  const backUrl = LOGIN_HOST.startsWith("//")
    ? `${window.location.protocol}${LOGIN_HOST}/openApp?appid=1`
    : `${LOGIN_HOST}/openApp?appid=1`
  const queryStr = query ? qs.stringify(query) : ""
  const bUrl = LOGIN_HOST.startsWith("//")
    ? `${window.location.protocol}${LOGIN_HOST}${path}${queryStr ? `?${queryStr}` : ""}`
    : `${LOGIN_HOST}${path}${queryStr ? `?${queryStr}` : ""}`

  if (pushOrReplace) {
    router.push({
      name: "Login",
      query: {
        backUrl,
        bUrl,
      },
    })
  } else {
    router.replace({
      name: "Login",
      query: {
        backUrl,
        bUrl,
      },
    })
  }
}
